import React, { useRef,useState } from 'react';
import emailjs from '@emailjs/browser';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import styled from "styled-components";
// import CountUp from 'react-countup';
import ScrollToTop from "../components/ScrollToTop";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import HeaderImage from "../assets/packages/Maldives-full.jpeg";
import { Alert, Modal } from '@mui/material';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

 export default function MaldivesTour() {
  const [startDate, setStartDate] = useState(null);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_tour', 'template_1gw9dz3', form.current, 'zU928UzQGhh0orQ7x')
      .then((result) => {
  
      }, (error) => {
          console.log(error.text);
      }
      );
      e.target.reset()
  };

  return (
    <>
      <ScrollToTop />
      <Navbar />

    <ServicesHeaderSection style={{ backgroundImage:`url(${HeaderImage})`,backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundSize:"cover" }}>
      <div className="overlay">
        <div className="main-container">
              <h1> Maldives Tour  </h1>
        </div>
      </div>
    </ServicesHeaderSection>


    <ServicesSection>
    <div className="main-container">
            
      <div className="tour-full-page">

          <div className="services-item">
            <div>

          <div className="tour-title-main">
            <div className="tour-title-wrapper">
                  <h3 className="tour-item-title">Maldives Tour</h3>
                  <div className="tour-item-price-holder">
                  <span className="tour-item-price">
                  <span className="tours-price-holder">
                  <span className="tours-item-price ">LKR  265,000</span>
                  </span>
                  </span>
                  <span className="tour-item-price-text">
                  / per person </span>
                  </div>
            </div>
        </div>
             
 
              <div className="tour-details-item-full">

                <p> The Maldives is an archipelagic state situated in the Indian Ocean known for its luxurious water villas. 
                  A tropical haven of white sand beaches, the Maldives is ideal for an adventure or leisure holiday. </p>

              {/* row */}
              <div className="tour-details-item">
                       <div> Male City </div>
                  </div>
                {/* row End */}

                 {/* row */}
              <div className="tour-details-item">
                       <div> Artificial Beach </div>
                  </div>
                {/* row End */}

                {/* row */}
              <div className="tour-details-item">
                       <div> Sultan Park </div>
                  </div>
                {/* row End */}

                {/* row */}
              <div className="tour-details-item">
                       <div> National Museum </div>
                  </div>
                {/* row End */}

                {/* row */}
              <div className="tour-details-item">
                       <div> Addu Atoll </div>
                  </div>
                {/* row End */}


                {/* row */}
              <div className="tour-details-item">
                       <div> Fulhadhoo Island </div>
                  </div>
                {/* row End */}

                {/* row */}
              <div className="tour-details-item">
                       <div> Vadhoo Island </div>
                  </div>
                {/* row End */}

              </div>




            </div>
          {/* Right side */}
            <div>

            {/* <div className='package-price'> 
                <span> LKR 179,800 </span> 
                <h6> / per person </h6>
            </div>  */}

                <div className="right-inqure-box">

            <h2> Book Now </h2>

            <a className="inqure-submit-btn hover-to-right" href="/inqure">Book Now</a>

            {/* <form ref={form} onSubmit={sendEmail}>
                  <div className="form-group">
                      <input required  type="text" name="user_name" placeholder="Enter your name*" />
                  </div>

                  <div className="form-group">
                      <input required type="text" name="user_email" placeholder="Enter your email*" />
                  </div>

                  <div className="form-group">
                  <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      disabledKeyboardNavigation
      name='user_date'
      placeholderText="Departure date *"/>
                  </div>

                  <div className="form-group">
                    <textarea required type="text" rows="4" name="message" placeholder="Message" />
                    <input type="hidden" name="tour_name" value="Singapore Tour" />
                  </div>

                  <div className="form-group">
                   


                    <Popup trigger={ <button type="submit" className="inqure-submit-btn hover-to-right"> Submit </button> } position="right center">
        <div className='thank-you-message'> Thank You for Your Inquiry </div>
      </Popup>




                  </div>

              </form> */}
            
                </div>
            </div>
          {/* Right side END */}

          </div>

          </div>
        </div>
      </ServicesSection>
      

      <Footer />
    </>
  );
}

const ServicesHeaderSection = styled.section`
overflow: hidden;
// padding: 14rem 0 8rem 0;


.overlay {
  background: rgb(0 0 0 / 40%);
  padding: 16rem 0 10rem 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


h1{
  color:#fff;
  font-size: 46px;
  text-transform: uppercase;
  font-family: 'Raleway',sans-serif;
  font-weight: 600;
  text-align: center;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  letter-spacing: 4px;
}

@media screen and (max-width: 425px){

  margin-bottom: 1rem;

  .overlay {
    background: rgb(0 0 0 / 40%);
    padding: 6rem 0 6rem 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  h1{
    font-size: 40px;
  }

}

`;


const ServicesSection = styled.section`
  overflow: hidden;
  padding: 0rem 0 4rem 0;
  // background-color: #f5f5f5;
  


.tour-full-page {
    // margin-top: 3rem;
    padding: 50px 50px;
    // background-color: #fff;
    // box-shadow: 0 3px 20px 5px rgb(0 0 0 / 3%);
    // box-sizing: border-box;
    // z-index: 99999;
}

.tour-title-main{
    margin-bottom: 30px;
}

.tour-title-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  // align-items: flex-start;
  align-items: center;
}

.tour-item-title {
  margin: 0 26px 0 0;
  font-size: 36px;
  line-height: 1.2em;
  font-weight: 600;
  // letter-spacing: -.05em;
  font-family: 'Raleway',sans-serif;
}


.tour-item-price-holder {
  white-space: nowrap;
}

tours-item-price {
  font-size: 24px;
  font-weight: 600;
  font-family: 'Raleway',sans-serif;
}

.tours-price-holder{
  margin-right: 14px;
}

.tours-item-price{
  color: #006a8f;
  font-weight:bold;
  font-size: 30px;
  font-family: 'Raleway',sans-serif;
}

.tour-details-item-full {
    margin-top: 60px;
}


.tour-details-item {
  display: grid;
  grid-template-columns: 99% ;
  margin-left: 1%;
  margin-bottom: 25px;
  font-family: 'Raleway',sans-serif;
}


  .services-item {
    overflow: hidden;
    gap:4%;
    display: grid;
    grid-template-columns: 68% 28%;
  }

  .services-item h2{
    margin-bottom: 20px;
    font-size: 30px;
    color: #000;
    text-transform: uppercase;
    font-family: 'Raleway',sans-serif;
    font-weight: 400;
    text-align: left;
  }

  .services-item p{
    position: relative;
    color: #000;
    font-size: 16px;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    margin-bottom: 30px;
    -webkit-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    line-height: 28px;
    text-align: justify;
}

.right-inqure-box{
  background: #006a8f;
  padding: 30px;
  overflow: hidden;
  border: rgb(100 100 111 / 20%) 1px solid;
  border-radius: 15px;
  text-align: center;
}

.right-inqure-box h2{
  margin-bottom: 20px;
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Raleway',sans-serif;
  font-weight: 500;
  text-align: center;
}

.form-group{
  padding: 10px 0;
  margin-bottom: 5px;
  overflow: hidden;
}

.form-group input,textarea{
  width: 100%;
  border: 1px solid #dfdfdf;
  padding: 20px 20px;
  font-size: 16px;
  font-family: 'Raleway',sans-serif;
  color:#000;
}

.form-group input,textarea:focus {
  outline:none;
}

.form-group select{
  width: 100%;
  border: 1px solid #dfdfdf;
  padding: 20px 20px;
  font-size: 16px;
  font-family: 'Raleway',sans-serif;
  color:#000;
}

.form-group select:focus {
  outline:none;
}


.inqure-submit-btn{
  cursor: pointer;
  background: #d0a133;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.1;
  font-weight: 400;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 15px 30px;
  border: 2px solid #fff;
  color: #fff;
  position: relative;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius:0px;
  -webkit-transition: background-color 0.3s,border-color 0.3s;
  -moz-transition: background-color 0.3s,border-color 0.3s;
  -ms-transition: background-color 0.3s,border-color 0.3s;
  -o-transition: background-color 0.3s,border-color 0.3s;
  -webkit-transition: background-color 0.3s,border-color 0.3s;
  transition: background-color 0.3s,border-color 0.3s;
}

.hover-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hover-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #006a8f;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hover-to-right:hover,
.hover-to-right:focus,
.hover-to-right:active {
  color: white;
}
.hover-to-right:hover:before,
.hover-to-right:focus:before,
.hover-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

  

  .alert {
    padding: 20px;
    background-color: #f44336;
    color: white;
  }
  
  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .closebtn:hover {
    color: black;
  }

  .thank-you-message{
    color: green;
    font-size: 16px;
    font-family: 'Raleway',sans-serif;
    font-weight: 400;
  }

  .package-price {
    background-color: #006a8f;
    border-radius: 20px;
    padding: 55px 20px;
    text-align: center;
    margin-bottom: 40px;
}

.package-price span {
    font-size: 50px;
    font-weight: 600;
    display: block;
    font-family: Raleway,sans-serif;
    margin-bottom: 10px;
    color:#fff;
}

.package-price h6{
    font-family: Raleway,sans-serif;
    color:#fff;
    font-size: 18px;
    font-weight: 500;
}


@media screen and (max-width: 768px) {
  
  .services-item {
    display: grid;
    grid-template-columns: 100%;
  }

  .tour-title-wrapper{
  display: contents;
  }

  .tour-item-price-text{
    display: block;
  }

  .tour-full-page{
    padding: 10px 10px;
  }

  .tour-title-main{
    margin-bottom: 10px;
  }

  .tour-details-item-full {
    margin-top: 40px;
  }

  .tour-details-item{
    margin-bottom: 30px;
  }


}


`;
