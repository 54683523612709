import React, { useEffect } from "react";
import Footer from "./components/Footer";
import Hero from "./components/Home/Hero";
import Navbar from "./components/Navbar";
import Destinations from "./components/Home/Destinations";
import ScrollToTop from "./components/ScrollToTop";
import Services from "./components/Home/Services";
import About from "./components/Home/About";
import Testimonials from "./components/Home/Testimonials";
import scrollreveal from "scrollreveal";
import Home from "./pages/Home";

// import {BrowserRouter as Router, Switch, Router} from 'react-router-dom';

export default function App() {
  useEffect(() => {
    const sr = scrollreveal({
      origin: "top",
      distance: "20px",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
        // nav,
        #hero,
        #services,
        #recommend,
        #testimonials
      
        `,
      {
        opacity: 0,
        interval: 300,
      }
    );
  }, []);
  return (
    <div>
      <Home />
    </div>
  );
}
