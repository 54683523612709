import React from "react";
import styled from "styled-components";

import image from "../../assets/three-travels-about-home.jpg";

export default function About() {
    return (
        <>


    <Aboutsection>

      <div className="main-container">

    <div className="about-left">
    <img  className="responsive" src={image} alt="" />
    </div>

      <div className="about-right">
        <div className="about-box-center">
          <p className="about-threetravel"> ABOUT THREE TRAVELS </p>
           <h2> View The World. </h2>
           <p className="about-box-center-paragraph"> 
          The company
specializes in travel and tours solutions. The core business activities include selling
and distribution of Air Tickets, Overseas hotel bookings and worldwide leisure travel,
family tour, visa processing and consultation. We are given opportunities for you to
learn different cultures with nature. You can get experience with us.
           </p>
          </div>
        </div>
      </div>

    </Aboutsection>
    </>
  );
}

const Aboutsection = styled.section`
overflow:hidden;
padding: 4rem 0 4rem 0;
background: #F8F8F8;



.about-us{
  width:100%;
  
  overflow:hidden;
}

.about-left{
  	width: 40%;
    float: left;
}

.about-left img{
  position: relative;
  display: block;
  overflow: hidden;
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 20px 50px 0px rgba(0,0,0,0.2);
}

.responsive {
  width: 100%;
  height: auto;
}

.about-right{
  width: 50%;
  float: left;
  padding-left: 10%;
   
}

.about-box-center {
  margin-top: 2rem;
}

.about-threetravel{
  font-family: Poppins,sans-serif;
  font-size: 11px;
  letter-spacing: 4px;
  line-height: 18px;
  text-align:left;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 10px;
}



.about-box-center h2 {
    margin-bottom: 20px;
    font-size: 36px;
    color: #000;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 400; 
    text-align:left;
}

.about-box-center-paragraph {
  position: relative;
  color: #000;
  font-size: 16px;
  font-family: Poppins,sans-serif;
  font-weight: 300;
  margin: 0px;
  transition: all 500ms ease;
  line-height: 28px;
  text-align: justify;
}



@media screen and (min-width: 280px) and (max-width: 1024px) {

    overflow:hidden;
    padding: 2rem 0 2rem 0; 

  .about-left{
  	width: 100%;
    float: left;
    
}

.about-right{
    width: 100%;
    float: left;
    padding-left: 0%;
}

.about-box-center h2 {
    text-align: center;
}

.about-threetravel {
  text-align: center;
}





}






`;
