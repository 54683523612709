import React from "react";
import styled from "styled-components";
import SliderImage2 from "../../assets/slider/slider2.jpg";
import SliderImage3 from "../../assets/slider/slider3.jpg";
import SliderImage7 from "../../assets/slider/slider7.jpg";
import SliderImage8 from "../../assets/slider/slider8.jpg";
import SliderImage10 from "../../assets/slider/slider10.jpg";
import SliderImage11 from "../../assets/slider/slider11.jpg";
import SliderImage12 from "../../assets/slider/slider12.jpg";


import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";



// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";


export default function Hero() {
  const data = [

    {
      sliderimage: SliderImage12,
    },
    {
      sliderimage: SliderImage10,
    },
    {
      sliderimage: SliderImage7,
    },
    {
      sliderimage: SliderImage3,
    },
    {
      sliderimage: SliderImage2,
    },
    {
      sliderimage: SliderImage8,
    },
    {
      sliderimage: SliderImage11,
    },
       
  ];

  return (
    <Swiper

    autoplay={{
      delay: 2500,
      disableOnInteraction: false,
    }}
        spaceBetween={30}
        effect={"fade"}
        navigation={true}
        pagination={{
          clickable: true,
          

        }}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
        className="mySwiper"
      >
        {data.map((slider) => {
          return (
        <SwiperSlide className="swiper-fixed-width-100">
        <img src={slider.sliderimage} alt="Three Travels" />
        </SwiperSlide>
        );
      })}

      </Swiper>
  );
}

const Section = styled.section`
  position: relative;
  margin-top: 2rem;
  width: 100%;
  height: 100%;

  .background {
    height: 100%;
    img {
      width: 100%;
      filter: brightness(60%);
    }
  }
  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    .title {
      color: white;
      h1 {
        font-size: 3rem;
        letter-spacing: 0.2rem;
      }
      p {
        text-align: center;
        padding: 0 30vw;
        margin-top: 0.5rem;
        font-size: 1.2rem;
      }
    }
    .search {
      display: flex;
      background-color: #ffffffce;
      padding: 0.5rem;
      border-radius: 0.5rem;
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 1.5rem;
        label {
          font-size: 1.1rem;
          color: #03045e;
        }
        input {
          background-color: transparent;
          border: none;
          text-align: center;
          color: black;
          &[type="date"] {
            padding-left: 3rem;
          }

          &::placeholder {
            color: black;
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        padding: 1rem;
        cursor: pointer;
        border-radius: 0.3rem;
        border: none;
        color: white;
        background-color: #4361ee;
        font-size: 1.1rem;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: #023e8a;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 980px) {
    height: 25rem;
    .background {
      background-color: palegreen;
      img {
        height: 100%;
      }
    }
    .content {
      .title {
        h1 {
          font-size: 1rem;
        }
        p {
          font-size: 0.8rem;
          padding: 1vw;
        }
      }
      .search {
        flex-direction: column;
        padding: 0.8rem;
        gap: 0.8rem;
        /* padding: 0; */
        .container {
          padding: 0 0.8rem;
          input[type="date"] {
            padding-left: 1rem;
          }
        }
        button {
          padding: 1rem;
          font-size: 1rem;
        }
        /* display: none; */
      }
    }
  }
`;
