import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import Aboutus from "./pages/about-us";
import Services from "./pages/services";
import Gallery from "./pages/gallery";
import Gallery1 from "./pages/gallery1";
import Packages from "./pages/packages";
import Contact from "./pages/contact";
import Inqure from "./pages/inqure";
import Services1 from "./pages/Airline_ticketing";
import Services2 from "./pages/out_bound_tours";
import Services3 from "./pages/visa_assistant";
import Services4 from "./pages/travel_insurance";
import Services5 from "./pages/Travel_consultation";
import Services6 from "./pages/Visa_consultation";
import ServicesOutbonudSub1 from "./pages/Out_bound_holiday_tour";
import ServicesOutbonudSub2 from "./pages/Out_bound_religious_tour";
import ServicesOutbonudSub3 from "./pages/Out_bound_leisure_tour";
import ServicesOutbonudSub4 from "./pages/Out_bound_exibition_tour";
import ServicesOutbonudSub5 from "./pages/Out_bound_honeymoon_tour";
import ServicesOutbonudSub6 from "./pages/Out_bound_special_tour";
import PrivacyPolicy from "./pages/Privacy_policy";
import TemsCondition from "./pages/terms_conditions";
import SingaporeTour from "./pages/singapore";
import IndiaTour from "./pages/india-tour";
import MalaysiaTour from "./pages/malaysia-tour";
import DubaiTour from "./pages/dubai-tour";
import BangkokTour from "./pages/bangkok-tour";
import MaldivesTour from "./pages/maldives-tour";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Helmet} from "react-helmet";

const StaticPage = ({ title, metade, canonLink, children }) => {
  return (
    <>
      <Helmet>
        <title>{title} | Three Travels & Tours (PVT) Ltd</title>
        <meta name="description" content={metade}/>

        <meta property="og:title" content="{title} | Three Travels & Tours (PVT) Ltd" />
        {
          canonLink && <link rel="canonical" href={canonLink} />
        }
    
      </Helmet>
      {children}
    </>
  );
}

ReactDOM.render(
  <>
  <Helmet>
    <meta charSet="utf-8" />
    <title>Three Travels & Tours (PVT) Ltd</title>

  </Helmet>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route 
        path="about-us"
        element={<StaticPage 
          title="About Us" 
          metade="">
          <Aboutus /></StaticPage>} 
      />

      <Route path="services" 
        element={<StaticPage 
          title="Services" 
          metade=""> 
          <Services /></StaticPage>} 
      />
      
      <Route path="gallery" 
        element={<StaticPage 
          title="Gallery" 
          metade=""> <Gallery /></StaticPage>} 
      />

      <Route path="gallery1"
        element={<StaticPage 
          title="Gallery" 
          metade=""> <Gallery1 /></StaticPage>} 
      />

      <Route path="packages" 
        element={<StaticPage 
          title="Tour Packages" 
          metade=""> <Packages /></StaticPage>} 
      />

      <Route path="contact" 
        element={<StaticPage 
          title="Contact Us" 
          metade=""> <Contact /></StaticPage>}
      />

      <Route path="services1" 
        element={<StaticPage 
          title="Airline Ticketing" 
          metade=""> <Services1 /></StaticPage>} 
      />

      <Route path="services2" 
        element={<StaticPage 
          title="Out Bound Tour" 
          metade=""> <Services2 /></StaticPage>} 
      />

      <Route path="services3" 
        element={<StaticPage 
          title="Visa Assistant" 
          metade=""> <Services3 /></StaticPage>} 
      />

      <Route path="services4" 
        element={<StaticPage 
          title="Travel Insurance" 
          metade=""> <Services4 /></StaticPage>} 
      />

      <Route path="services5" 
        element={<StaticPage 
          title="Travel Consultation" 
          metade=""> <Services5 /></StaticPage>} 
      />

      <Route path="services6" 
        element={<StaticPage 
          title="Visa Consultation" 
          metade=""> <Services6 /></StaticPage>} 
      />

      <Route path="holiday_tour" 
        element={<StaticPage 
          title="Holiday Tours" 
          metade=""> <ServicesOutbonudSub1 /></StaticPage>} 
      />

      <Route path="religious_tour" 
        element={<StaticPage 
          title="Religious Tours" 
          metade=""> <ServicesOutbonudSub2 /></StaticPage>} 
      />

      <Route path="leisure_tour" 
        element={<StaticPage 
          title="Leisure Tours" 
          metade=""> <ServicesOutbonudSub3 /></StaticPage>} 
      />

      <Route path="exibition_tour" 
        element={<StaticPage 
          title="Exhibition Tours" 
          metade=""> <ServicesOutbonudSub4 /></StaticPage>} 
      />

      <Route path="honymoon_tour" 
        element={<StaticPage 
          title="Couple Tours" 
          metade=""> <ServicesOutbonudSub5 /></StaticPage>} 
      />

      <Route path="special_tour" 
        element={<StaticPage 
          title="Special Tours" 
          metade=""> <ServicesOutbonudSub6 /></StaticPage>} 
      />

      <Route path="inqure" 
        element={<StaticPage 
          title="Inquire Request" 
          metade=""> <Inqure /></StaticPage>} 
      />

      <Route path="privacy-policy" 
        element={<StaticPage 
          title="Privacy Policy" 
          metade=""> <PrivacyPolicy /></StaticPage>} 
      />

      <Route path="terms-conditions" 
        element={<StaticPage 
          title="Terms and Conditions" 
          metade=""> <TemsCondition /></StaticPage>} 
      />

      <Route path="singapore-tour" 
        element={<StaticPage 
          title="Singapore Tour" 
          metade=""> <SingaporeTour /></StaticPage>} 
      />

      <Route path="india-tour" 
        element={<StaticPage 
          title="Manali Shimla (India) Tour" 
          metade=""> <IndiaTour /></StaticPage>} 
      />

      <Route path="malaysia-tour" 
        element={<StaticPage 
          title="Malaysia Tour" 
          metade=""> <MalaysiaTour /></StaticPage>} 
      />

      <Route path="dubai-tour" 
        element={<StaticPage 
          title="Dubai Tour" 
          metade=""> <DubaiTour /></StaticPage>} 
      />

      <Route path="bangkok-tour" 
        element={<StaticPage 
          title="Bangkok (Thailand) Tour" 
          metade=""> <BangkokTour /></StaticPage>} 
      />

      <Route path="maldives-tour" 
        element={<StaticPage 
          title="Maldives Tour" 
          metade=""> <MaldivesTour /></StaticPage>} 
      />
      

      
    </Routes>
  </BrowserRouter>
  </>,
  document.getElementById("root")
);
