import React, { useState } from "react";
import styled from "styled-components";
import logo from "../assets/whatsapp-icon.gif";
export default function ScrollToTop() {
  const [scrollState, setScrollState] = useState(false);
  const toTop = () => {
    // window.scrollTo({ top: 0 });
  };
  window.addEventListener("scroll", () => {
    window.pageYOffset > 100 ? setScrollState(true) : setScrollState(false);
  });
  return (
    <ToTop onClick={toTop} scrollState={scrollState}>
     <a  href="https://wa.me/94707123123?text=I'm interested in your sevices."> <img src={logo} alt="" /> </a> 
    </ToTop>
  );
}

const ToTop = styled.div`
  display: ${({ scrollState }) => (scrollState ? "block" : "none")};
  position: fixed;
  cursor: pointer;
  z-index: 10;
  bottom: 3rem;
  right: 0.6rem;
  img {
    height: 4rem;
  }
  border-radius: 2rem;
  background-color: #006a8f;
  padding: 0.2rem;
`;
