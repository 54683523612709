import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";


// import required modules
import { Autoplay, FreeMode, Navigation } from "swiper";

import { NavLink } from "react-router-dom";

import service1 from "../../assets/Air-logo/logo-air-01.png";
import service2 from "../../assets/Air-logo/logo-air-02.png";
import service3 from "../../assets/Air-logo/logo-air-03.png";
import service4 from "../../assets/Air-logo/logo-air-04.png";
import service5 from "../../assets/Air-logo/logo-air-05.png";
import service6 from "../../assets/Air-logo/logo-air-06.png";
import service7 from "../../assets/Air-logo/logo-air-07.png";
import service8 from "../../assets/Air-logo/logo-air-08.png";
import service9 from "../../assets/Air-logo/logo-air-09.png";

export default function airlogo() {
  const data = [
    {
      icon: service1,
    },
    {
      icon: service2,
    },
    {
      icon: service3,
    },
    {
      icon: service4,
    },
    {
      icon: service5,
    },
    {
      icon: service6,
    },
    {
        icon: service7,
      },
      {
        icon: service8,
      },
      {
        icon: service9,
      },
  ];
  return (
    <div className="main-container">
    <Section id="services">

    <Swiper
       autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}

        slidesPerView={6}
        spaceBetween={30}
        freeMode={true}
        
        pagination={{
          clickable: true,
        }}

        modules={[Autoplay, FreeMode, Navigation ]}
        className="mySwiper"

        breakpoints={{
          // when window width is >= 640px
          320: {
            slidesPerView: 1,
          },
          375: {
            slidesPerView: 1,
          },
          425: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },

          1024: {
            slidesPerView: 6,
          },
        }}
      >

      {data.map((airlogo, index) => {
        return (
          <SwiperSlide>
            <div className="next-tour-box">
                <img src={airlogo.icon} alt="" />   
            </div>
            </SwiperSlide>
        );
      })}
        
      </Swiper> 
      
      
         
        

      
    </Section>
    </div>
  );
}

const Section = styled.section`
  padding: 1rem 0 1rem 0;

  .package-item {
    overflow: hidden;
  }

  .next-tour-box{
    margin: 1rem;
    padding: 1rem;
    background-color: #fff;
    display: table-footer-group;
    overflow: hidden;
  }

  .next-tour-box img{
    width: 100%;
  }

  .next-right-box {
    width: 65%;
    float: left;
    font-size: 16px;
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    text-align: left;
}

.next-tour-dec{
    // width: 100%;
    // float: left;
    margin-top: 10px;
    color: #000;
    font-size: 14px;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    line-height: 20px;
    text-align: left;
}

  @media screen and (min-width: 576px) {
    .package-item {
      display: grid;
      grid-template-columns: 16.67% 16.67% 16.67% 16.67% 16.67% 16.67%;
    }
  }


  @media only screen and (max-width: 768px) {
    .package-item {
      display: grid;
      grid-template-columns: 50% 50%;
    }
  }

  @media only screen and (max-width: 425px) {
    .package-item {
      display: grid;
      grid-template-columns: 100%;
    }
  }


  .service-full {
    display: grid; 
    grid-template-columns: 16.66% 16.66% 16.66% 16.66% 16.66% ;
    padding: 1rem;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    border: 1px solid #80808021;
  }

  .service-full:hover {
    transform: translateX(0.4rem) translateY(-1rem);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .service {
    display: flex;
    grid-gap: 1.2rem;
    

    

    .icon {
      img {
        
        height: 2.4rem;
      }

    }
  }

.service-title{
    width: 100%;
}

.service h3 {
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  font-family: 'Raleway',sans-serif;
  font-weight: 600;
  text-align: left;
}

.service-paragraph {
  color: #000;
  font-size: 16px;
  font-family: Poppins,sans-serif;
  font-weight: 300;
  transition: all 500ms ease;
  line-height: 28px;
  text-align: center;
}

  @media screen and (min-width: 280px) and (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }

  
`;
