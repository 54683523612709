import React, { useState } from "react";
import styled from "styled-components";
import profile01 from "../../assets/ajith.jpeg";
import profile02 from "../../assets/guest-girl.png";

import { BsLinkedin, BsFacebook, BsHeadphones, BsMap, BsVoicemail } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";

export default function Team() {

  const data = [
    {
      avatar: profile01,
      name: "Mr.Ajith Kumarathanthri",
      postition: "Managing Director",
    },
    // {
    //   avatar: profile02,
    //   name: "Mrs.K.B.C.Thanthri",
    //   postition: "Secretary",
    // },
    // {
    //   avatar: profile02,
    //   name: "Mrs.M.Nissanka",
    //   postition: "Operation Manager",
    // },

  ];

  return (
    
    
    <TestimonialSection id="testimonials">

    <div className="main-container">

      <div className="title-center">
        <p> Three Travels </p>
        <h2> Our Team </h2>
      </div>

      <div className="team-item">
      {data.map((teamrow) => {
          return (
            <div className="team-shadow-box">
            <img src={teamrow.avatar} /> 
            <h2> {teamrow.name} </h2>
            <p> {teamrow.postition}  </p>
            <ul className="social__links">
        <li>
          <a className="social_margin_full" target="_blank" href="https://www.facebook.com/ajith.kumarathanthri"><BsFacebook /></a>
          <a className="social_margin_full" target="_blank" href="https://www.instagram.com/ajith_kumarathanthri/"><AiFillInstagram /></a>
          <a className="social_margin_full" target="_blank" href="https://www.linkedin.com/in/three-travels-02312b253"><BsLinkedin /></a>
        </li>
        
      </ul>
            </div>
            );
          })}
      </div>

      </div>

    </TestimonialSection>

    
  );
}

const TestimonialSection = styled.section`
    padding: 4rem 0 4rem 0;
    background: #F8F8F8;

  .title-center p{
    font-family: Poppins,sans-serif;
    font-size: 11px;
    -webkit-letter-spacing: 4px;
    -moz-letter-spacing: 4px;
    -ms-letter-spacing: 4px;
    letter-spacing: 4px;
    line-height: 18px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 10px;
}

  .title-center h2 {
    font-size: 36px;
    color: #000;
    text-transform: uppercase;
    font-family: 'Raleway',sans-serif;
    font-weight: 400;
    text-align: center;
}


.team-item{
  overflow: hidden;
  display: grid;
  
  width: 40%;
  margin: auto;
  // grid-template-columns: 33.33% 33.33% 33.33%;
}

@media screen and (max-width: 768px) {
.team-item{
  overflow: hidden;
  display: grid;
  width: 100%!important;
  margin: auto;
}

}

.team-shadow-box{
  padding: 1rem;
  border-radius: 0.7rem;
  transition: 0.3s ease-in-out;
  border: rgb(100 100 111 / 20%) 1px solid;
  margin: 50px;
}
.team-shadow-box:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.team-shadow-box img{
      width: 100%;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.team-shadow-box img:hover{
-webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
filter: grayscale(0%);
}

  .team-shadow-box h2{
    font-size: 20px;
    color: #000;
    text-transform: uppercase;
    font-family: 'Raleway',sans-serif;
    font-weight: 600;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.team-shadow-box p{
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
  font-family: 'Raleway',sans-serif;
  font-weight: 600;
  text-align: center;
}


@media screen and (max-width: 768px) {
  padding: 2rem 0 2rem 0;

  .team-item {
    // gap: 1rem;
    display: grid;
    grid-template-columns:100%;
  }

  .team-shadow-box {
    margin: 30px;
}

}


  .team-shadow-box {
    margin: 10px 20px 40px 20px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 10px 0px;
}

}






.testimonial-feedback-text{
  font-family: 'open Sans',sans-serif;
  color: #808080;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  line-height: 24px;
}



.social__links {
  margin-top: 20px;
 }

 .social_margin_full{
    margin: auto 10px;
 }

 .social__links li{
   gap: 6rem;
   flex-grow: 0; 
   flex-shrink: 0;
   color:#fff;
   text-align: center;
 }

 ul {
    
  list-style-type: none;
  gap: 2rem;
  li {
    margin-bottom: 10px;
    a {
      text-decoration: none;
      font-size: 16px;
      line-height: 26px;
      color: #fff;
      font-family: 'Raleway',sans-serif;
      transition: 0.3s ease-in-out;
      
      &:hover {
        color: #d0a133;
      }
    }
    svg {
     color:#006a8f;
      font-size: 1.8rem;
      transition: 0.3s ease-in-out;
      &:hover {
        color: #006a8f;
      }
    }
  }
}


 
`;
