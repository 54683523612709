import React from "react";
import styled from "styled-components";
// import CountUp from 'react-countup';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { NavLink } from "react-router-dom";

import HeaderImage from "../assets/Services/services-header.jpg";

import Services1 from "../assets/Services/air-ticketing.jpg";
import Services2 from "../assets/Services/out-bound-tours.jpg";
import Services3 from "../assets/Services/visa-assistant.jpg";
import Services4 from "../assets/Services/travel-insurance.jpg";
import Services5 from "../assets/Services/travel-consulation.jpg";
import Services6 from "../assets/Services/visa-consulation.jpg";

export default function Services() {

  const data = [
    {
      image: Services1,
      title: "HOLIDAY TOURS",
      subTitle: "Donec temporibus consectetuer, repudiandae integer pellentesque aliquet justo at sequi, atque quasi.",
      link: "/holiday_tour",
    },

    {
      image: Services2,
      title: "RELIGIOUS TOURS",
      subTitle: "Donec temporibus consectetuer, repudiandae integer pellentesque aliquet justo at sequi, atque quasi.",
      link: "/religious_tour",
    },

    {
      image: Services3,
      title: "LEISURE TOURS",
      subTitle: "Donec temporibus consectetuer, repudiandae integer pellentesque aliquet justo at sequi, atque quasi.",
      link: "/leisure_tour",
    },

    {
      image: Services4,
      title: "EXHIBITION TOURS",
      subTitle: "Donec temporibus consectetuer, repudiandae integer pellentesque aliquet justo at sequi, atque quasi.",
      link: "/exibition_tour",
    },

    {
      image: Services5,
      title: "Couple TOURS",
      subTitle: "Donec temporibus consectetuer, repudiandae integer pellentesque aliquet justo at sequi, atque quasi.",
      link: "/honymoon_tour",
    },

    {
      image: Services6,
      title: "SPECIAL TOURS",
      subTitle: "Donec temporibus consectetuer, repudiandae integer pellentesque aliquet justo at sequi, atque quasi.",
      link: "/special_tour",
    },
    
    
  ];

  return (
    <>
      
      <Navbar />

    <ServicesHeaderSection style={{ backgroundImage:`url(${HeaderImage})`,backgroundRepeat:"no-repeat", 
    backgroundPosition:"top", backgroundSize:"cover" }}>
      <div className="overlay">
        <div className="main-container">
              <h1> Out Bound Tour </h1>
        </div>
      </div>
    </ServicesHeaderSection>


    <ServicesSection>
        <div className="main-container">

          <div className="service-new-item">
              {/* Row Start */}
              {data.map((ServicesRow) => {
                return (
              <NavLink className="service-new-border-box"  to={ServicesRow.link} >
                    <div className="img-hover-zoom">
                      <img src={ServicesRow.image}  />
                    </div>
                      <div className="service-new-detail-div">
                            <h3 className="service-new-title"> {ServicesRow.title} </h3>
                            {/* <p> {ServicesRow.subTitle} </p> */}
                            
                              <a className="newservice-btn hover-to-right"> Find Out more</a>
                      </div>
                </NavLink>
                );
                })}
              {/* Row END */}


          </div>




              

          </div>
      </ServicesSection>
      

      <Footer />
    </>
  );
}

const ServicesHeaderSection = styled.section`
overflow: hidden;
// padding: 14rem 0 8rem 0;
margin-bottom: 3rem;

.overlay {
  background: rgb(0 0 0 / 40%);
  padding: 16rem 0 10rem 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


h1{
  color:#fff;
  font-size: 46px;
  text-transform: uppercase;
  font-family: 'Raleway',sans-serif;
  font-weight: 600;
  text-align: center;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  letter-spacing: 4px;
}

@media screen and (max-width: 425px){

  margin-bottom: 1rem;

  .overlay {
    background: rgb(0 0 0 / 40%);
    padding: 6rem 0 6rem 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  h1{
    font-size: 40px;
  }

}

`;


const ServicesSection = styled.section`
overflow: hidden;
padding: 4rem 0 4rem 0;


.service-new-item{
  overflow: hidden;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}


.service-new-border-box{
  // padding: 1rem;
  border-radius: 0rem;
  transition: 0.3s ease-in-out;
  // border: rgb(100 100 111 / 20%) 1px solid;
  margin: 28px;
  text-decoration: none;
}

.img-hover-zoom {
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
  background-color: #f8f8f8;
  }
  
  .img-hover-zoom img {
    transition: transform .5s ease;
  }
  
  .img-hover-zoom:hover img {
    transform: scale(1.2);
  }

.service-new-border-box img{
  width: 100%;
}



.service-new-detail-div {
  background-color: #f8f8f8;
  position: relative;
  padding: 30px;
  text-align: center;
}



.service-new-title {
text-align: center;
font-size: 24px;
color: rgb(0, 0, 0);
text-transform: uppercase;
font-family: Raleway, sans-serif;
font-weight: 500;
margin-bottom: 20px;
min-height: 40px;
position: relative;
}
.service-new-title:after {
content: "";
width: 50px;
background: #b7a368;
position: absolute;
bottom: -2px;
z-index: 66;
left: 0;
height: 1px;
right: 0;
margin: auto;
}


.service-new-detail-div p{
  position: relative;
  color: #000;
  font-size: 14px;
  font-family: Poppins,sans-serif;
  font-weight: 300;
  margin: 0px;
  -webkit-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  line-height: 28px;
  text-align: center;
}




.hover-to-right {
display: inline-block;
vertical-align: middle;
-webkit-transform: perspective(1px) translateZ(0);
transform: perspective(1px) translateZ(0);
box-shadow: 0 0 1px transparent;
position: relative;
-webkit-transition-property: color;
transition-property: color;
-webkit-transition-duration: 0.5s;
transition-duration: 0.5s;
}
.hover-to-right:before {
content: "";
position: absolute;
z-index: -1;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: #d0a133;
-webkit-transform: scaleX(0);
transform: scaleX(0);
-webkit-transform-origin: 0 50%;
transform-origin: 0 50%;
-webkit-transition-property: transform;
transition-property: transform;
-webkit-transition-duration: 0.5s;
transition-duration: 0.5s;
-webkit-transition-timing-function: ease-out;
transition-timing-function: ease-out;
}
.hover-to-right:hover,
.hover-to-right:focus,
.hover-to-right:active {
color: white;
}
.hover-to-right:hover:before,
.hover-to-right:focus:before,
.hover-to-right:active:before {
-webkit-transform: scaleX(1);
transform: scaleX(1);
}


.newservice-btn{
background: #006a8f;
width: auto;
position: relative;
padding: 14px 20px 14px 20px!important;
font-family: 'Raleway',sans-serif;
text-transform: uppercase;
font-size: 14px;
color: #fff;
margin: 30px 0 0 0;
text-decoration: none;

}




@media screen and (max-width: 768px) {
  .service-new-item {
    // gap: 2rem;
    display: grid;
    grid-template-columns: 100%;
  }

  .service-new-detail-div {
    background-color: #f8f8f8;
    position: relative;
    padding: 20px 15px;
    text-align: center;
  }

}

@media screen and (max-width: 425px) {
  
  .service-new-border-box{
      margin: 10px 14px 14px 14px;

  }

  .newservice-btn{
    margin: 10px 0 5px 0;
  }

}





.service-btn-fill-button {
  background-color: #d0a133;
  margin:0 1rem 0 1rem;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.1;
  font-weight: 400;
  text-decoration: none;
  padding: 15px 30px;
  border: 2px solid #d0a133;
  color: #fff;
  display: inline-block;
  position: relative;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  border-radius: 25px;

  -webkit-transition: background-color 0.3s, border-color 0.3s;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  -ms-transition: background-color 0.3s, border-color 0.3s;
  -o-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
.service-btn-fill-button:hover, .service-btn-fill-button:focus, .service-btn-fill-button:active {
  background-color: #006a8f;
  border-color: #006a8f;
  color: #ffffff;
}







.inner-service-wrap .bg-img-box .round-btn {
  margin-top: 20px;
}
@media screen and (max-width: 575px) {
  .inner-service-wrap {
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 1024px) {
  padding: 1rem 0 1rem 0;

}

`;
