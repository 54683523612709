import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import logowhite from "../assets/logo-three.png";
import FooterImage from "../assets/footer-bg.jpg";

import caa from "../assets/caa.png";
import sltda from "../assets/sltda-logo.png";


import { BsLinkedin, BsFacebook, BsHeadphones, BsMap, BsVoicemail } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
export default function Footer() {



  return (
    <>
    <FooterSection style={{ backgroundImage:`url(${FooterImage})`,backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundSize:"cover" }}>
      <div className="overlay">
    <div className="main-container">

    <div className="footercolumn-new">
        {/* site about */}
        <div>
        <img className="footer-logo" src={logowhite} alt="" />

        <p className="footercolumn-company-about"> Licensed by the Civil Aviation Authority of Sri Lanka. </p> 
        
        <ul className="social__links">
        <li>
          <a target="_blank" href="https://www.facebook.com/Three-Travels-106226758843760"><BsFacebook /></a>
        </li>
        <li>
          <a target="_blank" href="https://www.instagram.com/threetravelscom/"><AiFillInstagram /></a>
        </li>
        <li>
          <a target="_blank" href="https://www.linkedin.com/in/three-travels-02312b253"><BsLinkedin /></a>
        </li>
      </ul>


      <div className="new-footer-logo">
            <div>
            <img className="new-footer-logoimg" src={caa} alt="" />
            </div>

            <div>
            <img className="new-footer-logoimg" src={sltda} alt="" />
            </div>

      </div>        
        
      </div>
        {/* site about end */}
        {/* Services */}
        <div>
          <h1> Services </h1>

          <ul className="links">
        <li>
        <NavLink to="/"  >
          Home
        </NavLink>
        </li>
        <li>
        <NavLink to="/about-us" >
          About Us
        </NavLink>
        </li>
        <li>
        <NavLink to="/services" >
          Services
        </NavLink>
        </li>
        <li>
        <NavLink to="/packages" >
          Tour Package
        </NavLink>
        </li>
        <li>
        <NavLink to="/gallery" >
          Gallery
        </NavLink>
        </li>
        <li>
        <NavLink to="/contact" >
          Contact Us
        </NavLink>
        </li>
      </ul>

        </div>
        {/* Services END */}

        {/* Contact Us */}
        <div>
          <h1> Contacts </h1>

          <div className="footer-contact-details">
            <div> <BsMap /> </div>
             <div> Three Travels & Tours (PVT) Ltd <br/> No: 91/C/1 ,  <br/> Kandy Road,  Yakkala,  <br/> Sri Lanka </div> 
          </div>
          
          <div className="footer-contact-details">
            <div> <BsHeadphones /> </div>
             <div> +94 707 123 123  <br/>  +94 702 123 123 <br/> +94 333 123 123   </div> 
          </div>

          <div className="footer-contact-details">
            <div className="fax"> Fax </div>
             <div> +94 332 215 215 </div> 
          </div>

          <div className="footer-contact-details">
            <div> <BsVoicemail /> </div>
             <div>  threetravelsinternational@gmail.com </div> 
          </div>

             

        </div>
        {/* Contact Us end */}

      </div>





    </div>
    </div>
    </FooterSection>

    <Footerbottom>
    <div className="main-container">

        <div className="footer-bottom-full">

            <div className="footer-bottom-column">
                <div className="footer-copyright"> Copyright &copy; {new Date().getFullYear()} Three Travels & Tours. All rights reserved.</div>
            </div>

            <div className="footer-bottom-column">
            <ul className="footer-bottom-menu">
              {/* <li><a href="/">Sitemap</a></li> */}
              <li><a href="privacy-policy">Privacy Policy</a></li>
              <li><a href="terms-conditions">Terms of Use</a></li>
            </ul>  
            </div> 

            <div className="footer-bottom-column">
                  <div className="footer-designby"> Website Designed & Developed by  <a href="https://www.prolab.lk/">PRO LAB</a> </div>
            </div>

        </div>

        

    </div>
    </Footerbottom> 
    </>
  );
}

const FooterSection = styled.footer`

.overlay {
  background: rgb(0 0 0 / 60%);
  padding: 4rem 0 4rem 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

  
.new-footer-logo {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 50% 50%;
}

.new-footer-logoimg{
    width: auto;
    margin: 0 10%;
}

@media screen and (max-width: 768px) {
  .new-footer-logo {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

@media screen and (max-width: 425px) {
  .new-footer-logo {
    display: grid;
    grid-template-columns: 100%;
  }
}

// .footerfull {
//   display: flex;
//   gap: 6rem;
//   flex-grow: 0;
//   flex-shrink: 0;
//   @media screen and (min-width: 280px) and (max-width: 1024px) {
//     display: block;
//   }

  .footercolumn-new {
    // background-color: aliceblue;
    // padding: 0 2rem;
    // flex: 1;
    // justify-content: center;
    // display: table-cell;
    overflow: hidden;
    display: grid;
    grid-template-columns: 33.33333333% 33.33333333%  33.33333333%;
}

.footercolumn-new h1 {

    font-size: 24px;
    line-height: 32px;
    color: #d0a133;
    font-weight: 600;
    font-family: 'Raleway',sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.footercolumn-company-about {
  font-family: 'Raleway',sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #ffff;
  margin: 2rem 0;

}


.footer-contact-details{
  display: flex;

  // margin: 0 2rem;
  gap: 0.6rem;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: 'Raleway',sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  word-break: break-all;
  margin-bottom: 1rem;



  svg {
    
    color: #d0a133;
    font-size: 1.3rem;
    transition: 0.3s ease-in-out;
    &:hover {
      color: #d0a133;
    }
  } 
}



.social__links {
 display:flex;  
}
.social__links li{
  gap: 6rem;
  flex-grow: 0; 
  flex-shrink: 0;
  color:#fff;
  
}

}
  
  ul {
    
    list-style-type: none;
    gap: 2rem;
    li {
      margin-bottom: 10px;
      a {
        text-decoration: none;
        font-size: 16px;
        line-height: 26px;
        color: #fff;
        font-family: 'Raleway',sans-serif;
        transition: 0.3s ease-in-out;
        
        &:hover {
          color: #d0a133;
        }
      }
      svg {
       color:#d0a133;
        font-size: 1.8rem;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #006a8f;
        }
      }
    }
  }

  .fax {
    color:#d0a133;
     font-size: 14px;
     transition: 0.3s ease-in-out;
  }
  

.footermenuactivelink{
    color:#d0a133;
}

  @media screen and (min-width: 280px) and (max-width: 1024px) {
    // flex-direction: column;
    // gap: 2rem;

    ul {
      flex-direction: column;
    }
    .social__links {
      flex-direction: row;
    }
  }


  @media screen and (max-width: 768px) {
    // padding: 2rem 0 2rem 0;

    .footercolumn-company-about {
      text-align: center;
    }

    .footer-logo{
      text-align: center;
    }


}

@media screen and (max-width: 576px) {

  .overlay {
    background: rgb(0 0 0 / 60%);
    padding: 2rem 0 2rem 0; 
  }

    .footercolumn {
      padding: 0 1rem;
    }

    .footercolumn-new {
      overflow: hidden;
      display: grid;
      grid-template-columns: 100%;
      text-align: center;
  }


  .footer-contact-details{
      display: inherit;

  }


  .footercolumn-new h1 {
    margin-top: 30px;
  }


  .social__links {
    display:flex;  
    justify-content: center;
   }


}


`;

const Footerbottom = styled.div`
    font-family: Poppins,sans-serif;
    background:#fff;
    padding: 20px 0;

   


    .footer-bottom-full {
      display: flex;
      gap: 2rem;
      flex-grow: 0;
      flex-shrink: 0;
      
      @media screen and (min-width: 280px) and (max-width: 1024px) {
        display: block;
      }
    
      .footer-bottom-column {
        // padding: 2rem;
        flex: 1;
        
    }

      .footer-copyright{
        text-align:left;
        font-size: 14px;
        color: #000;
        line-height: 40px;
        
        @media screen and (min-width: 280px) and (max-width: 1024px) {
          text-align:center;
          margin-top: 10px;
        }
      }

      .footer-bottom-menu {
        text-align: center;
        padding: 0;
        margin: 0;
        list-style-type: none;
        border: none;
        font-size: 0;
    }

    .footer-bottom-menu li {
      display: inline-block;
  }

    .footer-bottom-menu li a {
      font-family: Poppins,sans-serif;
      font-size: 14px;
      color: #000;
      line-height: 40px;
      margin: 0 10px;
      text-decoration: none;
      transition: 0.3s ease-in-out;
    }

    .footer-bottom-menu li a:hover {
        color:#d0a133;
    }

    .footer-bottom-menu li a a:focus {
      color: #63236e;
    }
    .footer-designby{
      font-family: Poppins,sans-serif;
      text-align:right;
      font-size: 14px;
      color: #000;
      line-height: 40px;
      a{
        text-decoration: none;
        font-family: Poppins,sans-serif;
        color: #003b54;
        transition: 0.3s ease-in-out;
        font-weight:bold
      }
      @media screen and (min-width: 280px) and (max-width: 1024px) {
        text-align:center;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 5px 0;

      .footer-copyright{
        margin-top: 0px;
      }

   }
    
`
;