import React, { useRef,useState } from 'react';
import emailjs from '@emailjs/browser';
import styled from "styled-components";


import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

// import CountUp from 'react-countup';
import ScrollToTop from "../components/ScrollToTop";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ContactHeaderImage from "../assets/About-us/about-header.jpg";

import { AiOutlineMail, AiOutlinePhone, AiFillInstagram } from "react-icons/ai";

import { BsLinkedin, BsFacebook } from "react-icons/bs";


import { FaMap } from 'react-icons/fa';


export default function Contact() {

  const form = useRef();

  const sendEmail = (e) => {
    
      e.preventDefault();

      emailjs.sendForm('service_b8ldzza', 'template_93v4469', form.current, 'DSS7h6LQaQsyagFci')
        .then((result) => {
    
        }, (error) => {
            console.log(error.text);
        }
        );
    
      e.target.reset()
  };

  return (
    <>
      <ScrollToTop />
      <Navbar />

    <ContactHeaderSection style={{ backgroundImage:`url(${ContactHeaderImage})`,backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
        <div className="overlay">
              <div className="main-container">
                    <h1> Contact Us </h1>
              </div>
        </div>
    </ContactHeaderSection>


    <ContactContentSection>
        <div className="main-container">
        
            <div className="contact-box-main">
                  {/* Left Side */}
                    <div className="contact-left-side">
                      <p className="sub-title"> GET IN TOUCH </p>

                      <h2> REACH & CONTACT US! </h2>

                      <p> Contact us about anything related to our industry. We will try to give best
things for you with trust and quick service</p>

                        <ul className="contact-us-social-links">
        <li>
          <BsFacebook />
        </li>
        <li>
          <AiFillInstagram />
        </li>
        <li>
          <BsLinkedin />
        </li>
      </ul>

                        <p> 
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.349299644714!2d80.02814252694583!3d7.085444278515932!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2fc3da23e7307%3A0x2f9fbfff0ca06db9!2s91c%2C%201%20Kandy%20Rd%2C%20Yakkala%2011870!5e0!3m2!1sen!2slk!4v1663671758163!5m2!1sen!2slk" 
                        width="100%" className="map-border-none" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>  
                         </p>

                    </div>
                    {/* Left Side END */}

                    <div className="contact-from-wrap">

                    <form className="contact-from" ref={form} onSubmit={sendEmail}>
                                 <p>
                                    <label>Full Name*</label>
                                    <input type="text" name="user_name" placeholder="Enter Your Name"></input>
                                 </p>
                                 <p>
                                    <label>Email Address*</label>
                                    <input type="email" name="user_email" placeholder="Enter Your Email"></input>
                                 </p>
                                 <p>
                                    <label>Subject*</label>
                                    <input type="text" name="user_subject" placeholder="Enter Your Subject"></input>
                                 </p>
                                 <p>
                                    <label>Phone Number*</label>
                                    <input type="text" name="user_mobile" placeholder="Enter Your Phone Number"></input>
                                 </p>
                                 <p>
                                    <label>Comments / Questions</label>
                                    <textarea name="message" rows="8" placeholder="Enter Your Message"></textarea>
                                 </p>
                                 <p>
                                 <Popup trigger={ <button type="submit" className="hover-to-right contact-from-button"> Send Message </button> } position="right center">
                          <div> Thank You for Contact </div>
                        </Popup>
                                 </p>
                              </form>

                    </div>
            </div>

        </div>
    </ContactContentSection>


    <ContactDetailsSection>
        <div className="main-container">

            <div className="contact-details-box-main">

                <div className="contact-details-border-icon-box">
                <div className="box-icon"> <AiOutlineMail /> </div>
                  <div className="box-icon-inner"> 
                  <h4> EMAIL ADDRESS </h4> 
                      <ul>
                          <li>
                            <a href="mailto:threetravelsinternational@gmail.com">threetravelsinternational@gmail.com</a>
                          </li>
                        
                      </ul>
                  </div> 
                </div>

                <div className="contact-details-border-icon-box">
                <div className="box-icon"> <AiOutlinePhone /> </div>
                  <div className="box-icon-inner"> 
                  <h4> PHONE NUMBERS </h4> 
                      <ul>
                          <li>
                            
                            <a href="tel:+94707123123"> +94 707 123 123</a>
                          </li>
                          <li>
                            
                            <a href="tel:+94702123123"> +94 702 123 123</a>
                          </li>

                          <li>
                            <a href="tel:+94333123123"> +94 333 123 123 </a>
                          </li>
                        
                          
                          <li>
                            Fax : 
                            <a href="tel:+94332215215"> +94 332 215 215 </a>
                          </li>

                          


                          
                      </ul>
                  </div> 
                </div>

                <div className="contact-details-border-icon-box">
                <div className="box-icon"> <FaMap /> </div>
                  <div className="box-icon-inner"> 
                  <h4> ADDRESS LOCATION </h4> 
                      <ul>
                          <li>
                          Three Travels & Tours (PVT) Ltd
                          </li>
                          <li>
                          No: 91/C/1 ,
                          </li>
                          <li>
                          Kandy Road, Yakkala,
                          </li>
                          <li>
                          Sri Lanka
                          </li>
                          
                      </ul>
                  </div> 
                </div>

            </div>

        </div>
    </ContactDetailsSection>
      

      <Footer />
    </>
  );
}

const ContactHeaderSection = styled.section`
overflow: hidden;
// padding: 14rem 0 8rem 0;
margin-bottom: 3rem;

.overlay {
  background: rgb(0 0 0 / 40%);
  padding: 16rem 0 10rem 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


h1{
  color:#fff;
  font-size: 46px;
  text-transform: uppercase;
  font-family: 'Raleway',sans-serif;
  font-weight: 600;
  text-align: center;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  letter-spacing: 4px;
}

@media screen and (max-width: 425px){

  margin-bottom: 1rem;

  .overlay {
    background: rgb(0 0 0 / 40%);
    padding: 6rem 0 6rem 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  h1{
    font-size: 40px;
  }

}

`;


const ContactContentSection = styled.section`
  overflow: hidden;
  padding: 4rem 0 4rem 0;
  background: #fff;

  .contact-left-side{
    padding-left: 25px;
    padding-right: 25px;
  }

  .contact-left-side .sub-title {
    font-family: Poppins,sans-serif;
    font-size: 11px;
    -webkit-letter-spacing: 4px;
    -moz-letter-spacing: 4px;
    -ms-letter-spacing: 4px;
    -webkit-letter-spacing: 4px;
    -moz-letter-spacing: 4px;
    -ms-letter-spacing: 4px;
    letter-spacing: 4px;
    line-height: 18px;
    text-align: left;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.contact-left-side h2{
    font-family: 'Raleway',sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.contact-left-side p {
  position: relative;
  color: #000;
  font-size: 16px;
  font-family: Poppins,sans-serif;
  font-weight: 300;
  margin: 0px;
  -webkit-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  line-height: 28px;

}


.contact-us-social-links {
  margin-top: 30px;
  margin-bottom: 30px;
  display:flex;  
  justify-content: left;
 }

 .contact-us-social-links  li{
   flex-grow: 0; 
   flex-shrink: 0;
   color:#000;
   list-style-type: none;
   
 }

 .contact-us-social-links  li svg{
    font-size : 24px;
    margin-right: 1rem;
 }


 .map-border-none{
    border: none;
 }

  .contact-box-main {
  margin-left: 1%;
  margin-right: 1%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 48% 48%;
}


.contact-from-wrap{
  background: #006a8f;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  border-radius: 25px;
  padding: 40px 40px;

}


.contact-from-wrap .contact-from label {
  color: #ffffff;
  font-family: 'Raleway',sans-serif;
  font-size: 15px;
  
}
.contact-from-wrap .contact-from p {
  margin-bottom: 30px;
}
.contact-from-wrap .contact-from p:last-child {
  margin-bottom: 0;
}

.contact-from-wrap .contact-from input, textarea{
  width: 100%;
  margin-top: 8px;
background-color: #F8F8F8;
border: none;
-webkit-border-radius: 4px;
-moz-border-radius: 4px;
-ms-border-radius: 4px;
border-radius: 4px;
font-size: 14px;
padding: 20px 15px;
font-family: 'Raleway',sans-serif;
}

.contact-from-wrap .contact-from  textarea,input:focus{
  outline: none;
}


.contact-from-wrap .contact-from input:not([type=submit]):not([type=checkbox]):not([type=radio]) {
  width: 100%;
}


.contact-from-button {
  width: 100%;
  cursor: pointer;
  background: none;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.1;
  font-weight: 400;
  text-decoration: none;
  padding: 20px 30px;
  border: 2px solid #d0a133;
  color: #fff;
  display: inline-block;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;

  -webkit-transition: background-color 0.3s, border-color 0.3s;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  -ms-transition: background-color 0.3s, border-color 0.3s;
  -o-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
.contact-from-button:focus, .contact-from-button:active {
  background-color: #d0a133;
  border-color: #d0a133;
  color: #ffffff;
}


.hover-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hover-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d0a133;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hover-to-right:hover,
.hover-to-right:focus,
.hover-to-right:active {
  color: white;
}
.hover-to-right:hover:before,
.hover-to-right:focus:before,
.hover-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}




@media screen and (max-width: 991px) {
  .contact-from-wrap {
    margin-top: 40px;
  }
}


@media screen and (max-width: 768px) {
  padding: 1rem 0 1rem 0;

  .contact-left-side {
    padding-left: 0px;
    padding-right: 0px;
}

    .contact-box-main {
      display: grid;
      grid-template-columns: 100%;
    }
    
    .contact-left-side .sub-title{
      text-align: center;
    }

    .contact-left-side h2 {
      text-align: center;
    }

    .contact-us-social-links{
      justify-content: center ;
    }

  }
  @media screen and (max-width: 575px) {
    .contact-box-main {
      margin-bottom: 30px;
    }


}


`;


const ContactDetailsSection = styled.section`
    overflow: hidden;
    padding-top: 100px;
    padding-bottom: 70px;
    background: #F8F8F8;

    .contact-details-box-main {
      overflow: hidden;
      display: grid;
      grid-template-columns: 33.33333333% 33.33333333%  33.33333333%;
    }

    .contact-details-border-icon-box {
    border: 1px solid rgba(58, 120, 201, 0.16);
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    border-radius: 25px;
    margin-left: 18px;
    margin-right: 18px;
    text-align: center;
    padding: 40px;
    margin-bottom: 40px;
    transition: 0.3s ease-in-out;
  }

  .contact-details-border-icon-box:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }


  .box-icon {
    background-color: #006a8f;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%;
    color: #ffffff;
    line-height: 106px;
    height: 106px;
    width: 106px;
    font-size: 32px;
    display: inline-table;
    margin-bottom: 25px;
  }

  .box-icon-inner{
    width: 100%;
    margin-top: 10px;
  }

  .box-icon-inner h4{
    color: #223645;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    margin:0 0 14px 0;
  }
  
  .box-icon-inner  ul {
    margin: 0;
    padding: 0;
  }
  .box-icon-inner  li {
    font-family: 'Open Sans', sans-serif;
    list-style: none;
    margin-bottom: 5px;
  }
  .box-icon-inner  li:last-child {
    margin-bottom: 0;
  }
  .box-icon-inner  li a {
    font-family: 'Open Sans', sans-serif;
    color: #626672;
    text-decoration: none;
  }
  .box-icon-inner li a:hover {
    color: #006a8f;
  }


  @media screen and (max-width: 575px) {
    .contact-details-border-icon-box {
      padding: 20px 20px;
    }
    
    .box-icon {
      font-size: 22px;
      line-height: 80px;
      height: 80px;
      width: 80px;
    }
  }

    @media screen and (max-width: 768px) {

      padding-top: 3rem;
      padding-bottom: 3rem;

      .contact-details-box-main {
        display: grid;
        grid-template-columns: 100%;
      }
    }


`;
