import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
// import CountUp from 'react-countup';
import ScrollToTop from "../components/ScrollToTop";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import HeaderImage from "../assets/packages/tour-packages-header.jpeg";

// import { BsFillClockFill, BsFillMapFill } from "react-icons/bs";
import { AiFillClockCircle, AiOutlineUser, AiOutlineHeatMap } from "react-icons/ai";

import TourMalasiya from "../assets/packages/package-tour-malasiya.jpg";
import TourManali from "../assets/packages/manali-india.jpeg";
import TourSingapore from "../assets/packages/singapore.jpeg";
import TourDubai from "../assets/packages/dubai-tour.jpeg";
import Tourbangkok from "../assets/packages/thailand.jpeg";
import TourMaldives from "../assets/packages/Maldives.jpeg";

export default function Packages() {

  const data = [
    {
      TourImage: TourSingapore,
      TourName: "SINGAPORE TOUR ON EXPERIENCE THE GREAT HOLIDAY",
      TourDece: "A vibrant and inspiring Destination Singapore that we are proud of​.",
      TourDate: "2024 May",
      Maxperson: "12",
      TourCountry: "Singapore",
      TourPrice: "229,700",
      link: "/singapore-tour",
    },

    {
      TourImage: TourManali,
      TourName: "Manali Shimla  (India) TOUR ON EXPERIENCE THE GREAT HOLIDAY",
      TourDece: "One of the most popular hill stations in Himachal, Manali offers the most magnificent views of the Pir Panjal and the Dhauladhar ranges covered with snow for most parts of the year. ",
      TourDate: "2024 May",
      Maxperson: "12",
      TourCountry: "India",
      TourPrice: "274,800",
      link: "/india-tour",
    },
    
    {
      TourImage: TourMalasiya,
      TourName: "Malaysia TOUR ON EXPERIENCE THE GREAT HOLIDAY",
      TourDece: "Marketing Malaysia as a destination of excellence and to make the tourism industry a major contributor to the socio-economic development of the nation.",
      TourDate: "2024 May 22 - 26",
      Maxperson: "12",
      TourCountry: "Malaysia",
      TourPrice: "183,700",
      link: "/malaysia-tour",
    },

    {
      TourImage: TourDubai,
      TourName: "Dubai TOUR ON EXPERIENCE THE GREAT HOLIDAY",
      TourDece: "Once a small fishing village in the Arabian Gulf, Dubai is today one of the most cosmopolitan cities in the world. One of the seven emirates that make up the United Arab Emirates, the city is home to more than 200 nationalities and offers an unforgettable experience to all visitors.",
      TourDate: "2022 December",
      Maxperson: "12",
      TourCountry: "Dubai",
      TourPrice: "285,000",
      link: "/dubai-tour",
    },

    {
      TourImage: Tourbangkok,
      TourName: "Bangkok (Thailand) TOUR ON EXPERIENCE THE GREAT HOLIDAY",
      TourDece: "An increasing of tourists visiting Bangkok each year has proven that Bangkok is always a popular city for tourists. For major tourist attractions in Bangkok, most of them are historical sites or religious sites such as temples in Bangkok.",
      TourDate: "2022 Nevember",
      Maxperson: "12",
      TourCountry: "Thailand",
      TourPrice: "245,000",
      link: "/bangkok-tour",
    },

    {
      TourImage: TourMaldives,
      TourName: "Maldives TOUR ON EXPERIENCE THE GREAT HOLIDAY",
      TourDece: "Tourists travelling to the Maldives could stay in one of the four types of accommodation, Resorts, Hotels, Guesthouses and Safari",
      TourDate: "2023 January",
      Maxperson: "12",
      TourCountry: "Maldives",
      TourPrice: "265,000",
      link: "/maldives-tour",
    },

  ];

  return (
    <>
      <ScrollToTop />
      <Navbar />

      <PackageHeaderSection style={{ backgroundImage:`url(${HeaderImage})`,backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundSize:"cover" }}>
      <div className="overlay">
        <div className="main-container">
              <h1> Tour Packages </h1>
        </div>
      </div>
    </PackageHeaderSection>


    <PackageContentSection>
        <div className="main-container">
        
       {/* Tour Package */}
        {data.map((TourRow) => {
          return (
        <article className="package-item">
                <figure className="package-image" style={{ backgroundImage:`url(${TourRow.TourImage})`}}> </figure>
                        <div className="package-content">
                           <h3>
                           <NavLink to={TourRow.link} >
                              {TourRow.TourName}
                              </NavLink>
                           </h3>
                           <p>{TourRow.TourDece}</p>
                           
                           <div className="package-meta">
                              <ul>
                                 <li>
                                 <AiFillClockCircle />
                                 {TourRow.TourDate}
                                 </li>
                                 <li>
                                    <AiOutlineUser />
                                    pax: {TourRow.Maxperson}
                                 </li>
                                 <li>
                                    <AiOutlineHeatMap />
                                    {TourRow.TourCountry}
                                 </li>
                              </ul>
                           </div>
                        </div>

                <div className="package-price">   
                           <h6 className="price-list">
                              <span>LKR {TourRow.TourPrice}</span>
                              / per person
                           </h6>
                           <NavLink to={TourRow.link}  className="hover-to-right outline-btn outline-btn-white">Book now</NavLink>
                </div>
        </article>
         );
        })}
        {/* Tour Package END */}
       

          </div>
      </PackageContentSection>
      

      <Footer />
    </>
  );
}

const PackageHeaderSection = styled.section`
overflow: hidden;
// padding: 14rem 0 8rem 0;
margin-bottom: 3rem;

.overlay {
  background: rgb(0 0 0 / 40%);
  padding: 16rem 0 10rem 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


h1{
  color:#fff;
  font-size: 46px;
  text-transform: uppercase;
  font-family: 'Raleway',sans-serif;
  font-weight: 600;
  text-align: center;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  letter-spacing: 4px;
}

@media screen and (max-width: 425px){

  margin-bottom: 1rem;

  .overlay {
    background: rgb(0 0 0 / 40%);
    padding: 6rem 0 6rem 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  h1{
    font-size: 40px;
  }

}

`;


const PackageContentSection = styled.section`
  overflow: hidden;
  padding: 4rem 0 4rem 0;

  .package-item {
  background-color: #F9F9F9;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  margin-bottom: 50px;
  overflow: hidden;
}

.package-item .package-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.package-item .package-content {
  padding: 40px;
}

.package-item .package-content h3 a {
  font-size: 22px;
  line-height: 32px;
  color: #000;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  }

.package-item .package-content p {
    margin-top: 15px;
    margin-bottom: 25px;
    position: relative;
    color: #000;
    font-size: 16px;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    transition: all 500ms ease 0s;
    line-height: 28px;
}
.package-item .package-meta {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  border-radius: 25px;
  display: inline-block;
  padding: 10px 20px;
  position: relative;
}
.package-item .package-meta ul {
  font-size: 0;
  margin: 0;
  padding: 0;
}
.package-item .package-meta ul li {
  display: inline-block;
  font-size: 13px;
  line-height: 1.1;
  padding: 0 12px;
  position: relative;
}
.package-item .package-meta ul li svg {
  color: #3A78C9;
  margin-right: 6px;
}
.package-item .package-meta ul li:before {
  color: #BBBBBB;
  content: "|";
  position: absolute;
  right: -3px;
  top: 0;
}
.package-item .package-meta ul li:first-child {
  padding-left: 0;
}
.package-item .package-meta ul li:last-child {
  padding-right: 0;
}
.package-item .package-meta ul li:last-child:before {
  display: none;
}

.package-item .package-price {
  background-color: #006a8f;
  padding: 55px 20px;
  text-align: center;
}

.package-item .package-price h6{
  font-size: 14px;
  font-family: Raleway, sans-serif;
  font-weight: 400;
    
}

.package-item .package-price * {
  color: #ffffff;
}
.package-item .package-price .price-list {
  margin-bottom: 20px;
}
.package-item .package-price .price-list span {
  font-size: 50px;
  font-weight: 600;
  display: block;
  font-family: Raleway, sans-serif;
  margin-bottom: 10px;
}

.package-item .review-area {
  margin-bottom: 10px;
}

.package-item .rating-start-wrap {
  vertical-align: middle;
  margin-left: 10px;
}

// .package-item .rating-start-wrap .rating-start:before {
//   color: #C3C3C3;
// }
// .package-item .rating-start-wrap .rating-start span:before {
//   color: #ffffff;
// }



.outline-btn {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 400;
    text-decoration: none;
    padding: 15px 30px;
    border: 2px solid #d0a133;
    color: #fff;
    display: inline-block;
    position: relative;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;

    -webkit-transition: background-color 0.3s, border-color 0.3s;
    -moz-transition: background-color 0.3s, border-color 0.3s;
    -ms-transition: background-color 0.3s, border-color 0.3s;
    -o-transition: background-color 0.3s, border-color 0.3s;
    transition: background-color 0.3s, border-color 0.3s;
  }
  .outline-btn:focus, .outline-btn:active {
    background-color: #d0a133;
    border-color: #d0a133;
    color: #ffffff;
  }

  .hover-to-right {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
  .hover-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #d0a133;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hover-to-right:hover,
  .hover-to-right:focus,
  .hover-to-right:active {
    color: white;
  }
  .hover-to-right:hover:before,
  .hover-to-right:focus:before,
  .hover-to-right:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

@media screen and (min-width: 576px) {
    .package-item {
      display: grid;
      grid-template-columns: 33.33% 40% 26.67%;
    }
  }
  @media screen and (max-width: 575px) {
    .package-item {
      margin-bottom: 30px;
    }
    .package-item .package-image {
      min-height: 250px;
    }
    .package-item .package-content {
      padding: 25px 20px;
    }
    .package-item .package-meta ul li {
      font-size: 11px;
      padding: 0 9px;
    }
    .package-item .package-price {
      padding: 25px 20px;
    }
    .package-item .package-price .price-list span {
      font-size: 28px;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 1rem 0 1rem 0;

}

@media screen and (min-width: 768px) {
  .package-item .package-price .price-list span {
    font-size: 40px;
    font-weight: 600;
    display: block;
    font-family: Raleway,sans-serif;
    margin-bottom: 10px;
}

.outline-btn {
  padding: 15px 20px;
}

}

`;
