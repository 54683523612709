import React from "react";
import ScrollToTop from "../components/ScrollToTop";
import Services from "../components/Home/Services";
import Navbar from "../components/Navbar";
import Hero from "../components/Home/Hero";
import Destinations from "../components/Home/Destinations";
import Testimonials from "../components/Home/Testimonials";
import About from "../components/Home/About";
import Homewidthbanner from "../components/Home/Banner";
import Hometeam from "../components/Home/team";
import HomeAir from "../components/Home/AirLogo";

import Footer from "../components/Footer";

export default function Home() {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Hero />
      <Services />
      <About />
      <Destinations />
      <Homewidthbanner />
      <Testimonials />
      <Hometeam />
      <HomeAir />
      <Footer />
    </>
  );
}
