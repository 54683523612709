import React from "react";
import styled from "styled-components";

import HeaderImage from "../../assets/home-banner.jpg";

import { NavLink } from "react-router-dom";

export default function Homewidthbanner() {

  return (
    <>
      
      

    <GalleryHeaderSection className="home-callback bg-img-fullcallback" style={{ backgroundImage:`url(${HeaderImage})`,backgroundRepeat:"no-repeat", 
    backgroundPosition:"100% -113.3px, 0px top", backgroundSize:"cover", backgroundAttachment:"fixed" }}>
      <div className="overlay">
        <div className="main-container-banner">
              <h1> ARE YOU READY TO TRAVEL? <br/> REMEMBER US !! </h1>
              <p> A paradise in its true sense, The emerald isle is a destination that can satisfy the intense thirst for adventure! </p>
              <div class="callback-btn">
                    <NavLink to="/packages" className="callback-btn-fill-button">View Packages </NavLink>
                    <NavLink to="/about-us" className="callback-btn-outline-button">Learn More </NavLink>
              </div>
        </div>
      </div>
    </GalleryHeaderSection>

      
    </>
  );
}

const GalleryHeaderSection = styled.section`
  overflow: hidden;
  

  .overlay {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 13rem 0 11rem 0;
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .main-container-banner {
    width: 50%;
    margin: 0 auto;
}

  .bg-img-fullcallback {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    // padding-top: 115px;
    // padding-bottom: 115px;
    z-index: 1;
}
  .bg-img-fullcallback * {
    color: #ffffff;
  }
  // .bg-img-fullcallback .video-button {
  //   margin-bottom: 50px;
  // }
  // .bg-img-fullcallback p {
  //   margin-bottom: 40px;
  //   font-size: 16px;
  // }

  @media screen and (max-width: 575px) {
    .bg-img-fullcallback {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }
  
  .home-callback {
    padding-bottom: 185px;
  }
  @media screen and (max-width: 575px) {
    .home-callback {
      padding-bottom: 140px;
    }
  }


  h1{
    font-size: 40px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Raleway',sans-serif;
    font-weight: 600;
    text-align: center;

    margin: 0 0 15px;
    line-height: 1.2;
  }

  p{
    color: #fff;
    font-size: 16px;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    line-height: 28px;
    text-align: center;
    margin: 0 0 15px;
    word-wrap: break-word;
  }

.callback-btn {
    text-align:center;
    margin-top: 30px;
  }


  .callback-btn-outline-button {
    margin:0 1rem 0 1rem;
    cursor: pointer;
    background: none;
    font-family: 'Raleway',sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 400;
    text-decoration: none;
    padding: 15px 30px;
    border: 2px solid #fff;
    color: #fff;
    display: inline-block;
    position: relative;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    border-radius: 25px;
  
    -webkit-transition: background-color 0.3s, border-color 0.3s;
    -moz-transition: background-color 0.3s, border-color 0.3s;
    -ms-transition: background-color 0.3s, border-color 0.3s;
    -o-transition: background-color 0.3s, border-color 0.3s;
    transition: background-color 0.3s, border-color 0.3s;
  }
  .callback-btn-outline-button:hover, .callback-btn-outline-button:focus, .callback-btn-outline-button:active {
    background-color: #d0a133;
    border-color: #d0a133;
    color: #ffffff;
  }



  .callback-btn-fill-button {
    background-color: #d0a133;
    margin:0 1rem 0 1rem;
    cursor: pointer;
    font-family: 'Raleway',sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 400;
    text-decoration: none;
    padding: 15px 30px;
    border: 2px solid #d0a133;
    color: #fff;
    display: inline-block;
    position: relative;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    border-radius: 25px;
  
    -webkit-transition: background-color 0.3s, border-color 0.3s;
    -moz-transition: background-color 0.3s, border-color 0.3s;
    -ms-transition: background-color 0.3s, border-color 0.3s;
    -o-transition: background-color 0.3s, border-color 0.3s;
    transition: background-color 0.3s, border-color 0.3s;
  }
  .callback-btn-fill-button:hover, .callback-btn-fill-button:focus, .callback-btn-fill-button:active {
    background-color: #006a8f;
    border-color: #006a8f;
    color: #ffffff;
  }


  @media screen and (min-width: 280px) and (max-width: 1024px) {

    .overlay {
      background-color: rgba(0, 0, 0, 0.6);
      padding: 6rem 0 6rem 0;
    }
  
  }

  @media screen and (max-width: 776px) {
  .callback-btn-fill-button{
    margin-bottom: 30px;
  }

  .main-container-banner {
    width: 80%;
    margin: 0 auto;
  }


  


}

`;

