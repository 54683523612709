import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 
import ScrollToTop from "../components/ScrollToTop";
import Footer from "../components/Footer";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import PhotoAlbum from "react-photo-album";
import GalleryHeaderImage from "../assets/About-us/about-header.jpg";// This only needs to be imported once in your app

import Gallery01 from "../assets/Gallery/01gallery.jpg";
import Gallery02 from "../assets/Gallery/02gallery.jpg";
import Gallery04 from "../assets/Gallery/03gallery.jpg";
import Gallery05 from "../assets/Gallery/04gallery.jpg";
import Gallery06 from "../assets/Gallery/05gallery.jpg";
import Gallery07 from "../assets/Gallery/06gallery.jpg";
import Gallery08 from "../assets/Gallery/07gallery.jpg";
import Gallery09 from "../assets/Gallery/height02.jpg";
import Gallery10 from "../assets/Gallery/08gallery.jpg";
import Gallery11 from "../assets/Gallery/09gallery.jpg";
import Gallery12 from "../assets/Gallery/10gallery.jpg";
import Gallery13 from "../assets/Gallery/11gallery.jpg";
import Gallery14 from "../assets/Gallery/height03.jpg";
import Gallery15 from "../assets/Gallery/height04.jpg";
import Gallery16 from "../assets/Gallery/height05.jpg";

// Dubai 

import dubai01 from "../assets/Gallery/dubai/01.jpg";
import dubai02 from "../assets/Gallery/dubai/02.jpg";
import dubai03 from "../assets/Gallery/dubai/03.jpg";
import dubai04 from "../assets/Gallery/dubai/04.jpg";
import dubai06 from "../assets/Gallery/dubai/06.jpg";
import dubai07 from "../assets/Gallery/dubai/07.jpg";
import dubai08 from "../assets/Gallery/dubai/08.jpg";
import dubai09 from "../assets/Gallery/dubai/09.jpg";
import dubai10 from "../assets/Gallery/dubai/10.jpg";
import dubai11 from "../assets/Gallery/dubai/11.jpg";
import dubai12 from "../assets/Gallery/dubai/12.jpg";
import dubai13 from "../assets/Gallery/dubai/13.jpg";
import dubai14 from "../assets/Gallery/dubai/14.jpg";
import dubai15 from "../assets/Gallery/dubai/15.jpg";
import dubai16 from "../assets/Gallery/dubai/16.jpg";
import dubai17 from "../assets/Gallery/dubai/17.jpg";


// malaysia

import malaysia01 from "../assets/Gallery/malaysia01/01.jpg";
import malaysia02 from "../assets/Gallery/malaysia01/02.jpg";
import malaysia03 from "../assets/Gallery/malaysia01/03.jpg";
import malaysia04 from "../assets/Gallery/malaysia01/04.jpg";
import malaysia05 from "../assets/Gallery/malaysia01/05.jpg";
import malaysia06 from "../assets/Gallery/malaysia01/06.jpg";
import malaysia07 from "../assets/Gallery/malaysia01/07.jpg";
import malaysia08 from "../assets/Gallery/malaysia01/08.jpg";
import malaysia09 from "../assets/Gallery/malaysia01/09.jpg";
import malaysia10 from "../assets/Gallery/malaysia01/10.jpg";
import malaysia11 from "../assets/Gallery/malaysia01/11.jpg";
import malaysia12 from "../assets/Gallery/malaysia01/12.jpg";
import malaysia13 from "../assets/Gallery/malaysia01/13.jpg";
import malaysia14 from "../assets/Gallery/malaysia01/14.jpg";
import malaysia15 from "../assets/Gallery/malaysia01/15.jpg";
import malaysia16 from "../assets/Gallery/malaysia01/16.jpg";
import malaysia17 from "../assets/Gallery/malaysia01/17.jpg";
import malaysia18 from "../assets/Gallery/malaysia01/18.jpg";
import malaysia19 from "../assets/Gallery/malaysia01/19.jpg";
import malaysia20 from "../assets/Gallery/malaysia01/20.jpg";


const images = [
  Gallery01,
  Gallery02,
  Gallery04,
  Gallery05,
  Gallery06,
  Gallery07,
  Gallery08,
  Gallery09,
  Gallery10,
  Gallery11,
  Gallery12,
  Gallery13,
  Gallery14,
  Gallery15,
  Gallery16,


  dubai01,
  dubai02,
  dubai03,
  dubai04,
  dubai06,
  dubai07,
  dubai08,
  dubai09,
  dubai10,
  dubai11,
  dubai12,
  dubai13,
  dubai14,
  dubai15,
  dubai16,
  dubai17,


  malaysia01,
  malaysia02,
  malaysia03,
  malaysia04,
  malaysia05,
  malaysia06,
  malaysia07,
  malaysia08,
  malaysia09,
  malaysia10,
  malaysia11,
  malaysia12,
  malaysia13,
  malaysia14,
  malaysia15,
  malaysia16,
  malaysia17,
  malaysia18,
  malaysia19,
  malaysia20,

  
    
   
];
const photos = [
    
  {
    src : malaysia01,
    width: 1000,
    height: 900
},


{
  src : malaysia02,
  width: 1000,
  height: 900
},

{
  src : malaysia03,
  width: 1000,
  height: 900
},

{
  src : malaysia04,
  width: 1000,
  height: 900
},

{
  src : malaysia05,
  width: 1000,
  height: 900
},

{
  src : malaysia06,
  width: 1000,
  height: 900
},

{
  src : malaysia07,
  width: 1000,
  height: 900
},

{
  src : malaysia08,
  width: 1000,
  height: 900
},


{
  src : malaysia09,
  width: 1000,
  height: 900
},

{
  src : malaysia10,
  width: 1000,
  height: 900
},

{
  src : malaysia11,
  width: 1000,
  height: 900
},

{
  src : malaysia12,
  width: 1000,
  height: 900
},

{
  src : malaysia13,
  width: 600,
  height: 900
},

{
  src : malaysia14,
  width: 600,
  height: 900
},

{
  src : malaysia15,
  width: 600,
  height: 900
},

{
  src : malaysia16,
  width: 600,
  height: 900
},

{
  src : malaysia18,
  width: 600,
  height: 900
},

{
  src : malaysia19,
  width: 600,
  height: 900
},

{
  src : malaysia20,
  width: 600,
  height: 900
},



  {
        src : dubai01,
        width: 1000,
        height: 900
    },

    {
      src : dubai02,
      width: 600,
      height: 900
  },

  {
    src : dubai03,
    width: 600,
    height: 500
},

{
  src : dubai04,
  width: 600,
  height: 500
},

{
  src : dubai06,
  width: 600,
  height: 500
},

{
  src : dubai07,
  width: 600,
  height: 500
},

{
  src : dubai08,
  width: 600,
  height: 900
},

{
  src : dubai09,
  width: 600,
  height: 500
},

{
  src : dubai10,
  width: 600,
  height: 500,
},

{
  src : dubai11,
  width: 600,
  height: 500,
},

{
  src : dubai12,
  width: 600,
  height: 500,
},

{
  src : dubai13,
  width: 600,
  height: 900,
},

{
  src : dubai14,
  width: 600,
  height: 900,
},

{
  src : dubai15,
  width: 600,
  height: 900,
},

{
  src : dubai16,
  width: 600,
  height: 900,
},

{
  src : dubai17,
  width: 600,
  height: 900,
},






    {
      src : Gallery01,
      width: 1000,
      height: 900
  },
    
    {
        src: Gallery02,
        width: 1000,
        height: 900
    },
    {
        src: Gallery04,
        width: 800,
        height: 600
    },
    {
      src: Gallery05,
        width: 1000,
        height: 900
    },
    {
        src: Gallery06,
        width: 500,
        height: 400
    }, 
    {
        src: Gallery07,
        width: 800,
        height: 600
    },
    {
        src: Gallery08,
        width: 1000,
        height: 900
    },
    {
        src: Gallery09,
        width: 500,
        height: 600
    },
    {
      src : Gallery10,
      width: 1000,
      height: 900
  },
  {
    src : Gallery11,
    width: 1000,
    height: 900
},
{
  src : Gallery12,
  width: 1000,
  height: 900
},
{
  src : Gallery13,
  width: 1000,
  height: 900
},
{
  src : Gallery14,
  width: 600,
  height: 1000
},
{
  src : Gallery15,
  width: 600,
  height: 1000
},
{
  src : Gallery16,
  width: 600,
  height: 1000
},
];

export default class Gallery1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    return (
        
      <div>
        <ScrollToTop />
       <Navbar />

<GalleryHeaderSection style={{ backgroundImage:`url(${GalleryHeaderImage})`,backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
    <div className="overlay">
        <div className="main-container">
              <h1> Gallery </h1>
        </div>
    </div>
</GalleryHeaderSection>

<Gallerysectiontop>
        <div className="main-container">
          
        <PhotoAlbum layout="columns"  photos={photos} onClick={(event, photo, index) => this.setState({ isOpen: true })} />

          </div>
      </Gallerysectiontop>

      <Footer />

        {/* <button type="button" onClick={() => this.setState({ isOpen: true })}>
          Open Lightbox
        </button> */}

        {isOpen && (
          <Lightbox
    
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}
const GalleryHeaderSection = styled.section`
overflow: hidden;
// padding: 14rem 0 8rem 0;
margin-bottom: 3rem;

.overlay {
  background: rgb(0 0 0 / 40%);
  padding: 16rem 0 10rem 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


h1{
  color:#fff;
  font-size: 46px;
  text-transform: uppercase;
  font-family: 'Raleway',sans-serif;
  font-weight: 600;
  text-align: center;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  letter-spacing: 4px;
}

@media screen and (max-width: 425px){

  margin-bottom: 1rem;

  .overlay {
    background: rgb(0 0 0 / 40%);
    padding: 6rem 0 6rem 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  h1{
    font-size: 40px;
  }

}

`;


const Gallerysectiontop = styled.section`
  overflow: hidden;
  padding: 0 0 2rem 0;

  .about-us {
    width: 100%;
  }





`;