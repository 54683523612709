import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";


// import required modules
import { Autoplay, FreeMode, Pagination } from "swiper";


import profile01 from "../../assets/feedback/Asanka Nishan Wanigasooriya.jpeg";
import profile02 from "../../assets/feedback/Wajira Wanigasekara.jpeg";
import profile03 from "../../assets/feedback/Nadeesha Surangi.jpeg";
import profile04 from "../../assets/feedback/Sagara Sampath.jpeg";
import profile05 from "../../assets/feedback/Priyantha Wickramarathna.jpeg";
import profile06 from "../../assets/feedback/Milan Pitagaldeniya.jpeg";
export default function Testimonials() {
  const data = [
    {
      avatar: profile01,
      name: "Mr. Asanka Nishan Wanigasooriya",
      feedback: "We joined with Three Travels and we did joyful amazing tours with them,After we came back to Sri Lanka,Actually we are very happy now,We enjoyed very much with our family members,When thinking about our all expenses in this tour it was very valuable tour for us,They give us very valuable fair packages,Surely we will continue our all future tours with Three Travels,Thank You",
    },
    {
      avatar: profile02,
      name: "Mrs. Vajira Wanigasekara",
      feedback: "Happy to say that we had a great chance for having a most remarkable family tour with Three Travels. Even though our group was comprising with different aged levels, that our tour guide was truly cared about every and each one’s satisfaction for every instance. So, can be recommended as an outstanding, high-quality, customer-oriented service. We enjoyed every part of the tour including friendly transportation, accommodation and shopping etc. We are looking forward to have another travel experience with Three Travels.",
    },
    {
      avatar: profile03,
      name: "Mrs. Nadeesha Surangi",
      feedback: "I went with my family and the trip to Singapore was very successful and enjoyable and I highly appreciate Mr. Ajith Kumarathanthri's excellent service. I offer my best wishes for his future endeavors. Thank you very much",
    },
    {
      avatar: profile04,
      name: "Mr. Sagara Sampath",
      feedback: "Our Singapore tour is an amazing tour. We can’t explain how it is and, we are very happy. We got this opportunity from Three Travels before we met Three Travels we went so many tours but we didn’t feel happy from our previous tours. So we are very thankful for Mr. Ajith Kumarathanthri for giving this opportunity to us. If you like get our experience you can connect with Three Travels",
    },
    {
      avatar: profile05,
      name: "Mr. Priyantha Wickramarathna",
      feedback: "I am K.M.P. wickramarathne with my wife and three daughters, left for Singapore and Malaysia for the first time on 1/09/2022 for a holiday in a foreign country. It was for a period of 07 days. As it was the first experience with the three daughters and the wife, I had a sense of risk and fear. Especially my little one. Because my little daughter is 03 years old, the risk increased even more. But the support of the Three Travels company was received to the maximum. Therefore, due to being able to reduce the attention and fear, my wife and I were able to enjoy the vacation freely. The representative of Tree Travels company looked after all our needs and always checked if our luggage was there and helped us a lot in moving the luggage around. He explained all the visited places to the children so it was a useful holiday for the children. Because of the support given by Tree Travel Company, which was not unusual money we spent, the whole family was able to spend the holiday very happily. In Particular, an umbrella that my elder daughter had bought from Malaysia was lost at the Malaysia airport and my daughter was very sad about it because it is with passion. I am grateful for the honesty of your company in bringing it to Sri Lanka and giving it to me even after a week of coming to Sri Lanka",
    },
    {
      avatar: profile06,
      name: "Mr. Milan Pitagaldeniya",
      feedback: "This holiday had everything. Great people, food, wildlife, beaches and scenery",
    },
];

  return (
    
    
    <TestimonialSection>

    <div className="main-container">

      <div className="title-center">
        <p> Three Travels </p>
        <h2>Customer satisfaction</h2>
      </div>

      <Swiper
       autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}

        slidesPerView={3}
        spaceBetween={30}
        freeMode={true}
        
        pagination={{
          clickable: true,
        }}

        modules={[Autoplay, FreeMode, Pagination]}
        className="mySwiper"

        breakpoints={{
          // when window width is >= 640px
          320: {
            slidesPerView: 1,
          },
          375: {
            slidesPerView: 1,
          },
          425: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },

          1024: {
            slidesPerView: 3,
          },
        }}
      >
        {data.map((feedbackrow) => {
          return (
        <SwiperSlide>
        <div className="customer-feedback-box"> 
                <p> "{feedbackrow.feedback}" </p>
                <div className="feedback-image-name">
                  <div> <img src={feedbackrow.avatar} alt="" className="avatar-responsive" /> </div>
                  <div> <h4>{feedbackrow.name} </h4> </div>
                </div>
                
            </div>
        </SwiperSlide>
        );
      })}
        
      </Swiper>




      </div>

    </TestimonialSection>

    
  );
}

const TestimonialSection = styled.section`
    padding: 4rem 0 4rem 0;
    background: #fff;

  .title-center p{
    font-family: Poppins,sans-serif;
    font-size: 11px;
    -webkit-letter-spacing: 4px;
    -moz-letter-spacing: 4px;
    -ms-letter-spacing: 4px;
    letter-spacing: 4px;
    line-height: 18px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 10px;
}

  .title-center h2 {
    font-size: 36px;
    color: #000;
    text-transform: uppercase;
    font-family: 'Raleway',sans-serif;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
}

.testimonial-feedback-text{
  font-family: 'Montserrat', sans-serif;
  color: #808080;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  line-height: 24px;
}


.customer-feedback{
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
  // margin-bottom: 50px;
  overflow: hidden;
}

.customer-feedback-box{
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  transition: 0.3s ease-in-out;
  
  border: 1px solid rgba(58, 120, 201, 0.16);
  // margin: 28px;

  margin-bottom: 40px;

}
.customer-feedback-box:hover {
    // transform: translateX(0.4rem) translateY(-1rem);
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .avatar-responsive{
    width: 100%;
    border-radius: 10%;
    
  }

  .customer-feedback-box p{
    color: #000;
    font-size: 16px;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    margin: 0px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    line-height: 28px;
    text-align: justify;
    margin-bottom: 20px;
  }


  .customer-feedback-box h4{
    font-size: 16px;
    color: #000;
    text-transform: capitalize;
    font-family: 'Raleway',sans-serif;
    font-weight: 600;
    text-align: center;
    line-height: 24px;
    margin-left: 15px;
}

.feedback-image-name {
  // gap: 2rem;
  display: grid;
  grid-template-columns: 15% 85%;
  align-items: center;
}
.customer-feedback {
  // gap: 2rem;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

@media screen and (max-width: 768px) {
  padding: 2rem 0 2rem 0;

  .customer-feedback {
    // gap: 2rem;
    display: grid;
    grid-template-columns: 100%;
  }
}



  .testimonials {
    display: flex;
    justify-content: center;
    // margin: 0 2rem;
    gap: 3rem;

    .testimonial {
      background-color: #fff;
      padding: 2rem;
      border-radius: 0.5rem;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transition: 0.3s ease-in-out;
      &:hover {
        transform: translateX(0.4rem) translateY(-1rem);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      .info {
        display: flex;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        margin-top: 1rem;
        img {
          border-radius: 3rem;
          height: 3rem;
        }
        .details {
          span {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 768px) {
    .testimonials {
      flex-direction: column;
      margin: 0;
      .testimonial {
        justify-content: center;
        .info {
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
`;
