import React, { useRef,useState } from 'react';
import emailjs from '@emailjs/browser';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import styled from "styled-components";
// import CountUp from 'react-countup';
import ScrollToTop from "../components/ScrollToTop";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import HeaderImage from "../assets/inqure-header.jpg";

import VisaImage from "../assets/visa-inqure.png";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export default function Inqure() {
  const [startDate, setStartDate] = useState(null);
  const form = useRef();

  const sendEmail = (e) => {
    
      e.preventDefault();

      emailjs.sendForm('service_b8ldzza', 'template_6hojlmv', form.current, 'DSS7h6LQaQsyagFci')
        .then((result) => {
    
        }, (error) => {
            console.log(error.text);
        }
        );
    
      e.target.reset()
  };
  return (
    <>
      <ScrollToTop />
      <Navbar />

    <AboutHeaderSection style={{ backgroundImage:`url(${HeaderImage})`,backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundSize:"cover" }}>
      <div className="overlay">
        <div className="main-container">
              <h1> inquire Request </h1>
        </div>
      </div>
    </AboutHeaderSection>

  
      
      <Aboutsection>
        <div className="main-container">

          <div className="inqurebox-full-item">

            {/* LEFT */}
            <div className="inqure-left-box">  

            <h2> Any questions? Feel free to ask us! </h2>

            <p> Have a question or comment about us? We love hearing from you! 
              Complete the form and a member of our Customer Care team will get back to you soon. </p>
              
              <p>              
              Your Customer Care team is just a phone call away at </p>

              <div className="inqure-box-left-phone-number"> <a href="tel:94707123123">+94 707 123 123</a> </div>

              <img className="inqure-image-responsive" src={VisaImage} />
            
            </div>
            {/* Left END */}

            <div>

        <form ref={form} onSubmit={sendEmail}>
                <div className="grid-item">

                    <div>
                        <div className="form-group">
                            <input type="text" name="user_name" placeholder="Name*" required />
                        </div>
                    </div>

                    <div>
                        <div className="form-group">
                
                        <input type="email" name="user_email" placeholder="Email*" required/>
                        </div>
                    </div>

                    <div>
                        <div className="form-group">
                        <input type="number" name="user_mobile" placeholder="Mobile No*" required/>
                        </div>
                    </div>

                    <div>
                    <div className="form-group">
                            <select name="user_tour" placeholder="Select Your Tour* " required>
                                    <option select value="Airline ticketing">Airline ticketing</option>
                                    <option value="Visa Assistant">Visa Assistant</option>
                                    <option value="Travel Insurance">Travel Insurance</option>
                                    <option value="Travel Consultant">Travel Consultant</option>
                                    <option value="Visa Consultancy">Visa Consultancy</option>
                                    <option value="Outbound Tours">Outbound Tours</option>
                                    <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>

                </div>



                <div className="form-group">
                    <textarea type="text" rows="4" name="message" placeholder="Message"required></textarea>
                </div>

                <div className="form-group">
            
                          <Popup trigger={ <button type="submit" className="inqure-submit-btn hover-to-right"> Submit </button> } position="right center">
                          <div> Thank You for Your Inquiry </div>
                        </Popup>
            
      

                </div>
                
                </form>
                </div>

            </div>

        </div>
        
      </Aboutsection>



      <Footer />
    </>
  );
}

const AboutHeaderSection = styled.section`
overflow: hidden;
// padding: 14rem 0 8rem 0;
margin-bottom: 3rem;

.overlay {
  background: rgb(0 0 0 / 40%);
  padding: 16rem 0 10rem 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


h1{
  color:#fff;
  font-size: 46px;
  text-transform: uppercase;
  font-family: 'Raleway',sans-serif;
  font-weight: 600;
  text-align: center;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  letter-spacing: 4px;
}

@media screen and (max-width: 425px){

  margin-bottom: 1rem;

  .overlay {
    background: rgb(0 0 0 / 40%);
    padding: 6rem 0 6rem 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  h1{
    font-size: 40px;
  }

}

`;


const Aboutsection = styled.section`
  overflow: hidden;
  padding: 2rem 0 2rem 0;

  .inqurebox-full-item {
    overflow: hidden;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .inqure-left-box{
     overflow: hidden;
  }

  .inqure-left-box h2{
    margin-bottom: 20px;
    font-size: 30px;
    color: #000;
    text-transform: uppercase;
    font-family: 'Raleway',sans-serif;
    font-weight: 500;
    text-align: left;

  }


  .inqure-left-box p{
    margin-bottom: 20px;
    font-size: 16px;
    color: #000;
    line-height: 30px;
    font-family: 'Raleway',sans-serif;
    font-weight: 500;
    text-align: left;

  }

  .inqure-box-left-phone-number{
    font-size: 16px;
    color: #000;
    line-height: 30px;
    font-family: 'Raleway',sans-serif;
    font-weight: bold;
  }

  .inqure-box-left-phone-number a{
    font-size: 24px;
    color: #000;
    line-height: 34px;
    font-family: 'Raleway',sans-serif;
    font-weight: bold;
    text-decoration: none;
}



  .grid-item {
    overflow: hidden;
  }

  .form-group{
    padding: 20px;
    margin-bottom: 5px;
    overflow: hidden;
  }

  .form-group input,textarea{
    width: 100%;
    border: 1px solid #dfdfdf;
    padding: 20px 20px;
    font-size: 16px;
  }

  .form-group select{
    width: 100%;
    border: 1px solid #dfdfdf;
    padding: 20px 20px;
    font-size: 16px;
  }


  .inqure-submit-btn{
    cursor: pointer;
    background: #d0a133;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 400;
    -webkit-text-decoration: none;
    text-decoration: none;
    padding: 15px 30px;
    border: 2px solid #fff;
    color: #fff;
    position: relative;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background-color 0.3s,border-color 0.3s;
    -moz-transition: background-color 0.3s,border-color 0.3s;
    -ms-transition: background-color 0.3s,border-color 0.3s;
    -o-transition: background-color 0.3s,border-color 0.3s;
    -webkit-transition: background-color 0.3s,border-color 0.3s;
    transition: background-color 0.3s,border-color 0.3s;
  }


  @media screen and (min-width: 576px) {
    .grid-item {
      display: grid;
      grid-template-columns: 50% 50%;
    }
  }

  .inqure-image-responsive{
    width: 80%;
    margin: 30px auto 30px auto;
  }

  @media screen and (max-width: 768px){
    .inqurebox-full-item {
      display: grid;
      grid-template-columns: 100%;
  }

}

@media screen and (max-width: 425px){

  .inqure-left-box h2{
    text-align: center;
  }

  .form-group {
    padding: 5px 20px 0 20px;
    margin-bottom: 5px;
    overflow: hidden;

}

`;
